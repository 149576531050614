let inactivityTimeout: NodeJS.Timeout;

export const resetInactivityTimeout = (logout: () => void, inactivityLimit: number) => {
  clearTimeout(inactivityTimeout);
  inactivityTimeout = setTimeout(logout, inactivityLimit);
};

export const setupInactivityTracking = (logout: () => void, inactivityLimit: number) => {
  const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
  const resetTimeout = () =>  resetInactivityTimeout(logout, inactivityLimit);

  events.forEach(event => {
    window.addEventListener(event, resetTimeout);
  });

  resetInactivityTimeout(logout, inactivityLimit);

  return () => {
    events.forEach(event => {
      window.removeEventListener(event, resetTimeout);
    });
  };
};