import {
  realGetCompareCurrent,
  realGetCompareLive,
  realGetCoopMappedDataByItemID,
  realGetIngredientsByItemAndProductIds,
  realGetItemCategoryMapping,
  realGetItemRelationshipMapping,
  realGetItemList,
  realGetMarketingInformation,
  realGetNutritionFactsByProductIds,
  realGetProductsByItemId,
  realGetRegionsList,
  realGetRelationTypes,
  realGetSelectedItemRelationship,
  realGetSelectedItemsGoesWellWith,
  realSaveItemCategoryMapping,
  realSaveItemCoopMapping,
  realSaveItemGoesWellWith,
  realSaveItemMarketingInformation,
  realGetSelectedElementsMapping,
  realSaveItemProductMapping,
  realGetAllOptionalItems,
  realGetAllMutexGroups,
  realSaveItemRelationship,
  realItemGetReviewAndSubmit,
  realSaveItemReviewAndSubmit,
  realGetItemServingSize,
  realGetUomDetails,
  realRecalculateServingSize,
  realSaveItemServingSize,
  realGetProductDetailsByMutexId,
  realGetOptionalItemDetailsByMutexId,
  realSaveMutexGroup,
  realHardDeleteItem,
  realRestoreItem,
  realSoftDeleteItem,
  realGetArchiveItem,
  realSaveNutritionInformationByItemId,
  realDeleteSelectedItemRelationship,
  realSaveIsFortifiedApiRequest,
  realGetIsFortifiedItemValueQuery,
  realGetFortifiedItemsList,
  realUpdateFortifiedItems,
  realGetElementLiveStatus,
  realSaveHasComponent,
} from "./api/items";
import { paths } from "./items-v2.types";
import {
  mockGetCompareCurrent,
  mockGetCompareLive,
  mockGetCoopMappedDataByItemID,
  mockGetIngredientsByItemAndProductIds,
  mockGetItemCategoryMapping,
  mockGetItemRelationshipMapping,
  mockGetItemList,
  mockGetMarketingInformation,
  mockGetNutritionFactsByProductIds,
  mockGetProductsByItemId,
  mockGetRegionsList,
  mockGetRelationTypes,
  mockGetSelectedItemRelationship,
  mockGetSelectedItemsGoesWellWith,
  mockSaveItemCategoryMapping,
  mockSaveItemCoopMapping,
  mockSaveItemGoesWellWith,
  mockSaveItemMarketingInformation,
  mockGetSelectedElementsMapping,
  mockSaveItemProductMapping,
  mockGetAllOptionalItems,
  mockGetAllMutexGroups,
  mockSaveItemRelationship,
  mockItemGetReviewAndSubmit,
  mockSaveItemReviewAndSubmit,
  mockGetItemServingSize,
  mockGetUomDetails,
  mockRecalculateServingSize,
  mockSaveItemServingSize,
  mockGetProductDetailsByMutexId,
  mockGetOptionalItemDetailsByMutexId,
  mockSaveMutexGroup,
  mockGetArchiveItem,
  mockHardDeleteItem,
  mockRestoreItem,
  mockSoftDeleteItem,
  mockSaveNutritionInformationByItemId,
  mockDeleteSelectedItemRelationship,
  mockSaveIsFortifiedApiRequest,
  mockGetIsFortifiedItemValueQuery,
  mockGetFortifiedItemsList,
  mockUpdateFortifiedItems,
  mockGetElementLiveStatus,
  mockSaveHasComponent,
} from "./mock/items";
import {
  mockableDeleteRequest,
  mockableGetRequest,
  mockablePostRequest,
  mockablePutRequest,
} from "./mockable-api-request";

export const getItemList = mockableGetRequest<
  paths["/exp/v1/dna/item/getItemList"]
>(realGetItemList, mockGetItemList);

export const getItemMarketingInformation = mockableGetRequest<
  paths["/exp/v1/dna/item/getItemMarketingInformation"]
>(realGetMarketingInformation, mockGetMarketingInformation);

export const getProductsByItemId = mockableGetRequest<
  paths["/exp/v1/dna/item/getProductsByItemID"]
>(realGetProductsByItemId, mockGetProductsByItemId);

export const getIngredientsByItemAndProductIds = mockableGetRequest<
  paths["/exp/v1/dna/item/getIngredientByProductIds"]
>(realGetIngredientsByItemAndProductIds, mockGetIngredientsByItemAndProductIds);

export const getNutritionFactsByProductIds = mockableGetRequest<
  paths["/exp/v1/dna/item/getNutritionFactByProductsIDs"]
>(realGetNutritionFactsByProductIds, mockGetNutritionFactsByProductIds);
export const getItemCategoryMapping = mockableGetRequest<
  paths["/exp/v1/dna/item/getItemCategoryMapping"]
>(realGetItemCategoryMapping, mockGetItemCategoryMapping);

export const getSelectedItemsGoesWellWith = mockableGetRequest<
  paths["/exp/v1/dna/item/getSelectedItemsGoesWellWith"]
>(realGetSelectedItemsGoesWellWith, mockGetSelectedItemsGoesWellWith);

export const saveItemMarketingInformation = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemMarketingInformation"]
>(realSaveItemMarketingInformation, mockSaveItemMarketingInformation);
//Added for Platform 15485 amanjare
export const saveHasComponentApiCall = mockablePostRequest<
  paths["/exp/v1/dna/item/saveHasComponent"]
>(realSaveHasComponent, mockSaveHasComponent);

export const saveIsFortifiedApiRequest = mockablePostRequest<
  paths["/exp/v1/dna/item/saveFortifiedItemsList"]
>(realSaveIsFortifiedApiRequest, mockSaveIsFortifiedApiRequest);

export const getIsFortifiedItemValueQuery = mockableGetRequest<
  paths["/exp/v1/dna/item/getIsFortifiedValue"]
>(realGetIsFortifiedItemValueQuery, mockGetIsFortifiedItemValueQuery);

export const getFortifiedItemsListQuery = mockableGetRequest<
  paths["/exp/v1/dna/item/getFortifiedIems"]
>(realGetFortifiedItemsList, mockGetFortifiedItemsList);

export const updateFortifiedItems = mockablePostRequest<
  paths["/exp/v1/dna/item/updateFortifiedItems"]
>(realUpdateFortifiedItems, mockUpdateFortifiedItems);


export const saveItemCategoryMapping = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemCategoryMapping"]
>(realSaveItemCategoryMapping, mockSaveItemCategoryMapping);
export const getCompareCurrent = mockableGetRequest<
  paths["/exp/v1/dna/item/getCompareCurrent"]
>(realGetCompareCurrent, mockGetCompareCurrent);

export const getCompareLive = mockableGetRequest<
  paths["/exp/v1/dna/item/getCompareLive"]
>(realGetCompareLive, mockGetCompareLive);

export const saveItemGoesWellWith = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemGoesWellWith"]
>(realSaveItemGoesWellWith, mockSaveItemGoesWellWith);

export const getRegionsList = mockableGetRequest<
  paths["/exp/v1/dna/item/getRegionsList"]
>(realGetRegionsList, mockGetRegionsList);

export const getCoopMappedDataByItemID = mockableGetRequest<
  paths["/exp/v1/dna/item/getCoopMappedDataByItemID"]
>(realGetCoopMappedDataByItemID, mockGetCoopMappedDataByItemID);

export const saveItemCoopMapping = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemCoopMapping"]
>(realSaveItemCoopMapping, mockSaveItemCoopMapping);

export const getSelectedElementsMapping = mockableGetRequest<
  paths["/exp/v1/dna/item/getSelectedElementsMapping"]
>(realGetSelectedElementsMapping, mockGetSelectedElementsMapping);

export const saveItemProductMapping = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemProductMapping"]
>(realSaveItemProductMapping, mockSaveItemProductMapping);

export const getAllOptionalItems = mockableGetRequest<
  paths["/exp/v1/dna/item/optionalItemList"]
>(realGetAllOptionalItems, mockGetAllOptionalItems);

export const getAllMutexGroups = mockableGetRequest<
  paths["/exp/v1/dna/item/getMutexList"]
>(realGetAllMutexGroups, mockGetAllMutexGroups);
export const getItemRelationshipMapping = mockableGetRequest<
  paths["/exp/v1/dna/item/getItemRelationshipMapping"]
>(realGetItemRelationshipMapping, mockGetItemRelationshipMapping);

export const getSelectedItemRelationship = mockableGetRequest<
  paths["/exp/v1/dna/item/getSelectedItemRelationship"]
>(realGetSelectedItemRelationship, mockGetSelectedItemRelationship);

export const getRelationTypes = mockableGetRequest<
  paths["/exp/v1/dna/item/getRelationTypes"]
>(realGetRelationTypes, mockGetRelationTypes);

export const saveItemRelationship = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemRelationship"]
>(realSaveItemRelationship, mockSaveItemRelationship);

export const getItemReviewAndSubmit = mockableGetRequest<
  paths["/exp/v1/dna/item/getItemReviewAndSubmit"]
>(realItemGetReviewAndSubmit, mockItemGetReviewAndSubmit);

export const saveItemReviewSubmit = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemReviewSubmit"]
>(realSaveItemReviewAndSubmit, mockSaveItemReviewAndSubmit);

export const getItemServingSize = mockableGetRequest<
  paths["/exp/v1/dna/item/getItemServingSize"]
>(realGetItemServingSize, mockGetItemServingSize);

export const getUomDetails = mockableGetRequest<
  paths["/exp/v1/dna/item/getUomDetails"]
>(realGetUomDetails, mockGetUomDetails);

export const recalculateServingSize = mockableDeleteRequest<
  paths["/exp/v1/dna/item/recalculateServingSize"]
>(realRecalculateServingSize, mockRecalculateServingSize);

export const saveItemServingSize = mockablePostRequest<
  paths["/exp/v1/dna/item/saveItemServingSize"]
>(realSaveItemServingSize, mockSaveItemServingSize);
export const getProductDetailsByMutexId = mockableGetRequest<
  paths["/exp/v1/dna/item/getProductDetailsByMutexId"]
>(realGetProductDetailsByMutexId, mockGetProductDetailsByMutexId);

export const getOptionalItemDetailsByMutexId = mockableGetRequest<
  paths["/exp/v1/dna/item/getOptionalItemDetailsByMutexId"]
>(realGetOptionalItemDetailsByMutexId, mockGetOptionalItemDetailsByMutexId);

export const saveMutexGroup = mockablePostRequest<
  paths["/exp/v1/dna/item/saveMutexGroup"]
>(realSaveMutexGroup, mockSaveMutexGroup);

export const getArchiveItem = mockableGetRequest<
  paths["/exp/v1/dna/item/getArchiveItems"]
>(realGetArchiveItem, mockGetArchiveItem);

export const hardDeleteItem = mockableDeleteRequest<
  paths["/exp/v1/dna/item/hardDeleteItem"]
>(realHardDeleteItem, mockHardDeleteItem);

export const restoreItem = mockablePutRequest<
  paths["/exp/v1/dna/item/restoreItem"]
>(realRestoreItem, mockRestoreItem);

export const softDeleteItem = mockablePutRequest<
  paths["/exp/v1/dna/item/softDeleteItem"]
>(realSoftDeleteItem, mockSoftDeleteItem);

export const saveNutritionInformationByItemId = mockablePostRequest<
  paths["/exp/v1/dna/item/saveNutritionInformationByItemId"]
>(realSaveNutritionInformationByItemId, mockSaveNutritionInformationByItemId);

export const deleteSelectedItemRelationship = mockableDeleteRequest<
  paths["/exp/v1/dna/item/deleteSelectedItemRelationship"]
>(realDeleteSelectedItemRelationship, mockDeleteSelectedItemRelationship);

export const getElementLiveStatus = mockableGetRequest<
  paths["/exp/v1/dna/getElementLiveStatus"]
>(realGetElementLiveStatus, mockGetElementLiveStatus);