import { useCallback, useContext, useMemo, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import { getCompareLive, getCompareCurrent } from "../data/products";
import {
  FormattedProductCompareDetails,
  ValidProductImage,
  formatProductCompare,
} from "../utils";
import { uniq } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { LanguageSelector } from "../components/LanguageSelector";
import { components } from "../data/products.types";
import { useCustomQuery } from "../hooks/use-custom-query";
import {
  standardizedNumStringOrNA,
  localizedNumStringOrNA,
} from "../util/number-localization";

type ProductAttributeName = keyof Omit<
  FormattedProductCompareDetails,
  "nutrientFacts" | "attachProductThumbnailImage" | "id"
>;
type Nutrient = Omit<
  components["schemas"]["Nutrient"],
  "nutrientNameId" | "id"
>;

export const ProductCompareWithLive = () => {
  const { productId } = useParams();
  const { selectedCountry, defaultLanguage } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );

  if (!productId) {
    throw new Error("productId is required");
  }

  const compareLiveQuery = useCustomQuery(
    [
      "product/getCompareLive",
      {
        productId,
        countryCode: selectedCountry,
        languageCode: selectedLanguage,
      },
    ],
    () =>
      getCompareLive(apiClient)({
        productId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );
  const liveCompareData = useMemo(() => {
    if (compareLiveQuery.data?.data.component) {
      return formatProductCompare(compareLiveQuery.data.data.component);
    }
    return undefined;
  }, [compareLiveQuery.data]);

  const compareCurrentQuery = useCustomQuery(
    [
      "product/getCompareCurrent",
      {
        productId,
        countryCode: selectedCountry,
        languageCode: selectedLanguage,
      },
    ],
    () =>
      getCompareCurrent(apiClient)({
        productId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );
  const currentCompareData = useMemo(() => {
    if (compareCurrentQuery.data?.data.component) {
      return formatProductCompare(compareCurrentQuery.data.data.component);
    }
    return undefined;
  }, [compareCurrentQuery.data]);

  const isFetching = useMemo(
    () =>
      Boolean(compareLiveQuery.isFetching || compareCurrentQuery.isFetching),
    [compareLiveQuery.isFetching, compareCurrentQuery.isFetching]
  );
  const isError = useMemo(
    () => Boolean(compareLiveQuery.isError && compareCurrentQuery.isError),
    [compareLiveQuery.isError, compareCurrentQuery.isError]
  );

  const getLiveAttributeValue = useCallback(
    (key: ProductAttributeName) => {
      if (liveCompareData && typeof liveCompareData[key] !== "undefined") {
        return String(liveCompareData[key]);
      }
      return "--";
    },
    [liveCompareData]
  );

  const getCurrentAttributeValue = useCallback(
    (key: ProductAttributeName) => {
      if (
        currentCompareData &&
        typeof currentCompareData[key] !== "undefined"
      ) {
        return String(currentCompareData[key]);
      }
      return "--";
    },
    [currentCompareData]
  );

  const pageTitle = useMemo(() => {
    const productName = getCurrentAttributeValue("productName");
    return productName
      ? `Compare With Live (${productName})`
      : `Compare With Live`;
  }, [getCurrentAttributeValue]);

  const nutrientFactNames = useMemo(() => {
    const liveNutrientNames = liveCompareData
      ? liveCompareData.nutrientFacts.nutrient.map(
          (liveNutrient) => liveNutrient.name
        )
      : [];
    const currentNutrientNames = currentCompareData
      ? currentCompareData.nutrientFacts.nutrient.map(
          (currentNutrient) => currentNutrient.name
        )
      : [];
    return uniq([...liveNutrientNames, ...currentNutrientNames]);
  }, [liveCompareData, currentCompareData]);

  // NUTRIENTS
  const liveNutrients = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.nutrientFacts.nutrient;
    }
  }, [liveCompareData]);
  const currentNutrients = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.nutrientFacts.nutrient;
    }
  }, [currentCompareData]);

  const getLiveNutrientValue = useCallback(
    (nutrientName: string, fieldName: keyof Nutrient) => {
      if (liveNutrients) {
        const nutrient = liveNutrients.find((n) => n.name === nutrientName);
        if (nutrient) {
          return nutrient[fieldName];
        }
      }
      return "";
    },
    [liveNutrients]
  );
  const getCurrentNutrientValue = useCallback(
    (nutrientName: string, fieldName: keyof Nutrient) => {
      if (currentNutrients) {
        const nutrient = currentNutrients.find((n) => n.name === nutrientName);
        if (nutrient) {
          return nutrient[fieldName];
        }
      }
      return "";
    },
    [currentNutrients]
  );
  // PRODUCT IMAGE
  const liveImageDetails = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.attachProductThumbnailImage;
    }
  }, [liveCompareData]);
  const currentImageDetails = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.attachProductThumbnailImage;
    }
  }, [currentCompareData]);

  const getLiveImageValue = useCallback(
    (key: keyof ValidProductImage) => {
      if (liveImageDetails) {
        return liveImageDetails[key];
      }
    },
    [liveImageDetails]
  );

  const getCurrentImageValue = useCallback(
    (key: keyof ValidProductImage) => {
      if (currentImageDetails) {
        return currentImageDetails[key];
      }
    },
    [currentImageDetails]
  );

  const getAttributeRow = useCallback(
    (key: ProductAttributeName, fieldName: string) => {
      const liveValue = getLiveAttributeValue(key);
      const currentValue = getCurrentAttributeValue(key);
      if (typeof liveValue !== "string" && typeof currentValue !== "string") {
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      if (typeof currentValue === "string" && typeof liveValue !== "string") {
        // show changed if current has value but live is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#FCEFCC",
              }}
            >
              {currentValue}
            </TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      if (typeof liveValue === "string" && typeof currentValue !== "string") {
        // show changed if live has value but current is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#FCEFCC",
              }}
            ></TableCell>
            <TableCell>{liveValue}</TableCell>
          </StyledTableRow>
        );
      }
      return (
        <StyledTableRow
          key={key}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row">
            {fieldName}
          </TableCell>
          <TableCell
            sx={{
              backgroundColor: currentValue !== liveValue ? "#FCEFCC" : "white",
            }}
          >
            {currentValue}
          </TableCell>
          <TableCell>{liveValue}</TableCell>
        </StyledTableRow>
      );
    },
    [getLiveAttributeValue, getCurrentAttributeValue]
  );

  const getImageRow = useCallback(
    (key: keyof ValidProductImage, fieldName: string) => {
      const liveImageValue = getLiveImageValue(key);
      const currentImageValue = getCurrentImageValue(key);

      const hasDiff = () => {
        if (typeof currentImageValue !== typeof liveImageValue) {
          return true;
        }
        return Boolean(
          typeof currentImageValue === "string" &&
            typeof liveImageValue === "string" &&
            currentImageValue !== liveImageValue
        );
      };

      if (
        typeof liveImageValue !== "string" &&
        typeof currentImageValue !== "string"
      ) {
        // render empty if both live & current are blank
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell>--</TableCell>
            <TableCell>--</TableCell>
          </StyledTableRow>
        );
      }
      if (
        typeof currentImageValue === "string" &&
        typeof liveImageValue !== "string"
      ) {
        // show changed if current has value but live is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <StyledTableCellWithDiff>
              {currentImageValue}
            </StyledTableCellWithDiff>
            <TableCell>--</TableCell>
          </StyledTableRow>
        );
      }
      if (
        typeof liveImageValue === "string" &&
        typeof currentImageValue !== "string"
      ) {
        // show changed if live has value but current is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <StyledTableCellWithDiff>--</StyledTableCellWithDiff>
            <TableCell>{liveImageValue}</TableCell>
          </StyledTableRow>
        );
      }

      return (
        <StyledTableRow
          key={key}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row">
            {fieldName}
          </TableCell>
          {hasDiff() ? (
            <StyledTableCellWithDiff>
              {currentImageValue}
            </StyledTableCellWithDiff>
          ) : (
            <TableCell>{currentImageValue}</TableCell>
          )}
          <TableCell>{liveImageValue}</TableCell>
        </StyledTableRow>
      );
    },
    [getLiveImageValue, getCurrentImageValue]
  );

  const ProductCompareMarketingDetailsTable = () => {
    const displayPim = (selectedCountry === 'UK'
      || selectedCountry === 'DE')
      //added this for country level display For Platform 16124 amanjare start
     const displayGenesisId= (selectedCountry === 'UK'
      || selectedCountry === 'IE')
      const displayGenesisName= (selectedCountry === 'UK'
        || selectedCountry === 'IE' ||selectedCountry === 'US'
        || selectedCountry === 'CA') 
        //added this for country level display For Platform 16124 amanjare End
    return (
      <>
        {isFetching && (
          <StyledLoaderContainer pageSize={12}>
            <Loader />
          </StyledLoaderContainer>
        )}

        <TableContainer component={Paper}>
          <Table aria-label="Product Compare Marketing Details Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Current Value</TableCell>
                <TableCell>Live Value</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {getAttributeRow("productType", "Product Type")}
              {getAttributeRow("productName", "Product Name")}
              {getAttributeRow(
                "productMarketingName",
                "Product Marketing Name"
              )}
            
              {displayGenesisName && getAttributeRow(
                "genesisProductName",
                "Genesis Product Name"
              )}
              {getAttributeRow("productId", "Product ID")}
              {displayGenesisId && getAttributeRow("genesisId", "Genesis Id")}
              {getAttributeRow("productNo", "Product No.")}
              {displayPim && getAttributeRow("pimId", "PIM ID")}
              {displayPim &&
                getAttributeRow("pimProductName", "PIM Product Name")}
              {getAttributeRow("productWrinNo", "WRIN #")}
              {getAttributeRow("ingredientStatement", "Ingredient Statement")}
              {getAttributeRow("productAllergen", "Product Allergen")}
              {getAttributeRow(
                "productAdditionalTextIngredientStatement",
                "Additional Text for Ingredient Statement"
              )}
              {getAttributeRow(
                "productAdditionalAllergen",
                "Additional Allergen"
              )}
              {getAttributeRow("productComments", "Product Comments")}
              {getAttributeRow("product_keywords", "Product Keywords")}
            </TableBody>
          </Table>
          {isError && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No product marketing information to display`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </>
    );
  };
  const ProductCompareImageDetailsTable = () => {
    return (
      <>
        {isFetching && (
          <StyledLoaderContainer pageSize={5}>
            <Loader />
          </StyledLoaderContainer>
        )}
        <TableContainer component={Paper}>
          <Table>
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Image Attribute</TableCell>
                <TableCell>Current Value</TableCell>
                <TableCell>Live Value</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>{getImageRow("imageName", "Image Name")}</TableBody>
          </Table>
          {isError && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No image information to display`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </>
    );
  };
  const ProductCompareNutrientsTable = () => {
    const getNutrientRow = useCallback(
      (nutrientName: string, index: number) => {
        const currentValue = localizedNumStringOrNA(
          getCurrentNutrientValue(nutrientName, "value"),
          selectedCountry!
        );
        const liveValue = localizedNumStringOrNA(
          getLiveNutrientValue(nutrientName, "value"),
          selectedCountry!
        );
        const currentFloatValue = standardizedNumStringOrNA(
          currentValue,
          selectedCountry!
        );
        const liveFloatValue = standardizedNumStringOrNA(
          liveValue,
          selectedCountry!
        );
        const currentHundredGValue = localizedNumStringOrNA(
          getCurrentNutrientValue(nutrientName, "hundredGPerProduct"),
          selectedCountry!
        );
        const liveHundredGValue = localizedNumStringOrNA(
          getLiveNutrientValue(nutrientName, "hundredGPerProduct"),
          selectedCountry!
        );
        const uom = getCurrentNutrientValue(nutrientName, "uom");

        const currentHundredGFloatValue = standardizedNumStringOrNA(
          currentHundredGValue,
          selectedCountry!
        );
        const liveHundredGFloatValue = standardizedNumStringOrNA(
          liveHundredGValue,
          selectedCountry!
        );
        const hasDiff =
          currentFloatValue !== liveFloatValue ||
          currentHundredGFloatValue !== liveHundredGFloatValue;
        return (
          <StyledTableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell>{nutrientName}</TableCell>
            <TableCell>{uom}</TableCell>
            <TableCell
              style={{
                borderLeft: "1px solid black",
                backgroundColor: hasDiff ? "#FCEFCC" : "white",
              }}
            >
              {currentValue === "N/A" ? currentValue : `${currentValue} (-%)`}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: hasDiff ? "#FCEFCC" : "white",
              }}
            >
              {currentHundredGValue}
            </TableCell>
            <TableCell style={{ borderLeft: "1px solid black" }}>
              {liveValue === "N/A" ? liveValue : `${liveValue} (-%)`}
            </TableCell>
            <TableCell style={{ borderRight: "1px solid black" }}>
              {liveHundredGValue}
            </TableCell>
          </StyledTableRow>
        );
      },
      []
    );
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}></TableCell>
                <TableCell align="center" colSpan={2}>
                  Current Value
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Live Value
                </TableCell>
              </TableRow>
            </TableHead>
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Nutrition Name</TableCell>
                <TableCell>UoM</TableCell>
                <TableCell>Nutrition Value</TableCell>
                <TableCell>100g per product</TableCell>
                <TableCell>Nutrition Value</TableCell>
                <TableCell>100g per product</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {nutrientFactNames && nutrientFactNames.length > 0 && (
              <TableBody>
                {nutrientFactNames.map((nutrientName, index) =>
                  getNutrientRow(nutrientName, index)
                )}
              </TableBody>
            )}
          </Table>
          {(!nutrientFactNames || nutrientFactNames.length === 0) && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No nutrition information to display`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </StyledRelativeContainer>
    );
  };
  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid mobile={12}>
        <Grid mobile={12}>
          <Typography variant="h1">{pageTitle}</Typography>
        </Grid>
        <Grid
          mobile={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ backgroundColor: "#DA291C" }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid
          container
          mobile={12}
          sx={{ mb: 2, mt: 2, justifyContent: "flex-end" }}
        >
          <LanguageSelector
            selectedLanguage={selectedLanguage!}
            onChange={(language) => setSelectedLanguage(language)}
          />
        </Grid>
        <Grid mobile={12}>
          <Stack spacing={1} marginBottom="20px">
            <Grid container spacing={1}>
              <Grid mobile={12} sx={{ mt: 4 }}>
                <Typography variant="h2">Products & Ingredients</Typography>
              </Grid>
              <Grid mobile={12}>
                <ProductCompareMarketingDetailsTable />
              </Grid>
              <Grid mobile={12} sx={{ mt: 4 }}>
                <Typography variant="h2">Product Image Details</Typography>
              </Grid>
              <Grid mobile={12}>
                <ProductCompareImageDetailsTable />
              </Grid>
              <Grid mobile={12} sx={{ mt: 4 }}>
                <Typography variant="h2">Nutritional Information</Typography>
              </Grid>
              <Grid mobile={12}>
                <ProductCompareNutrientsTable />
              </Grid>
              <StyledGrid container mobile={12}>
                <Grid
                  sx={{
                    backgroundColor: "#FCEFCC",
                    height: "36px",
                    width: "80px",
                    mx: 1,
                  }}
                />
                <Typography variant="h6">Changes done in WXB</Typography>
              </StyledGrid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </StyledRelativeContainer>
  );
};

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 36,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledGrid = styled(Grid)({
  marginTop: "8px",
  alignItems: "center",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
const StyledLoaderContainer = styled("div", {
  shouldForwardProp: (prop) => !["pageSize", "isEmpty"].includes(String(prop)),
})<
  React.HTMLAttributes<HTMLDivElement> & {
    pageSize: number;
    isEmpty?: Boolean;
  }
>(({ pageSize, isEmpty }) => {
  const getNonEmptyTableHeight = () => 32 + pageSize * 36;
  return {
    height: Boolean(isEmpty) ? 120 : getNonEmptyTableHeight(),
    position: "relative",
  };
});
const StyledTableCellWithDiff = styled(TableCell)({
  backgroundColor: "#FCEFCC",
});

