import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  TableSortLabel,
  Button,
  MenuItem,
  TextField,
  Switch,
  styled,
  MenuList,
  TablePagination,
  ListItemText,
  IconButton,
  TableContainer,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SearchInput } from "./SearchInput";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  CloseOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { StyledDashboardTableHead } from "./CategoryMapping";
import {
  ItemWithCategories,
  ValidItemCollectionItem,
  createSortByStringFn,
} from "../utils";
import { DataContext } from "../data-provider";
import { Loader } from "./loader/Loader";
import { SortOrder } from "../data/mock/types";

type ItemTableSortField = "itemId" | "itemName" | "status" | "itemType";

const sortByName = createSortByStringFn<ItemWithCategories>("itemName");
const sortById = createSortByStringFn<ItemWithCategories>("itemId");

interface CollectionItem {
  associatedItemId: number;
  associatedItemName?: string;
  associatedCategoryId?: number;
  associatedCategoryName?: string;
  isChangeable: string;
  displayOrder: number;
}

export const ItemCollection = ({
  chosenCollectionItems,
  onItemCollectionChange,
  language,
  defaultLanguage,
}: {
  chosenCollectionItems: ValidItemCollectionItem[];
  onItemCollectionChange: (data: ValidItemCollectionItem[]) => void;
  language: string;
  defaultLanguage: string; 
}) => {
  const isDefaultLanguage = language === defaultLanguage;
  const handleAddToChosenCollection = (item: ItemWithCategories) => {
    if (!isDefaultLanguage) return; 
    const newCollectionItem: ValidItemCollectionItem = {
      associatedCategoryId: 0,
      associatedCategoryName: "",
      associatedItemId: item.itemId,
      associatedItemName: item.itemName,
      isChangeable: "N",
      displayOrder: chosenCollectionItems.length + 1,
    };

    onItemCollectionChange([...chosenCollectionItems, newCollectionItem]);
  };

  return (
    <Grid container mobile={12}>
      <Grid mobile={4}>
        <SelectItemsTable
          chosenCollectionItems={chosenCollectionItems}
          onClickAddItem={handleAddToChosenCollection}
          disabled={!isDefaultLanguage}
        />
      </Grid>
      <Grid mobile={8}>
        <ChosenCollectionItemsTable
          chosenCollectionItems={chosenCollectionItems}
          onItemCollectionChange={onItemCollectionChange}
          disabled={!isDefaultLanguage}
        />
      </Grid>
    </Grid>
  );
};

const SelectItemsTable = ({
  chosenCollectionItems,
  onClickAddItem,
  disabled,
}: {
  chosenCollectionItems: ValidItemCollectionItem[];
  onClickAddItem: (item: ItemWithCategories) => void;
  disabled: boolean; 
}) => {
  const { items: allItems, categoryListLoading } = useContext(DataContext);

  const [page, setPage] = useState(0);
  const pageSize = 5;
  const [sortField, setSortField] = useState<ItemTableSortField>("itemName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredSortedItems, setFilteredSortedItems] =
    useState<ItemWithCategories[]>(allItems);

  useEffect(() => {
    const searchLower = searchQuery.toLowerCase();
    const filteredItems =
      searchQuery.length > 0
        ? allItems?.filter((item) => {
            return (
              item.itemName?.toLowerCase().includes(searchLower) ||
              item.itemId.toString().includes(searchQuery)
            );
          })
        : allItems;
    if (sortField === "itemName") {
      setFilteredSortedItems(sortByName(filteredItems, sortOrder));
    } else if (sortField === "itemId") {
      setFilteredSortedItems(sortById(filteredItems, sortOrder));
    }
    setPage(0);
  }, [searchQuery, allItems, sortField, sortOrder]);

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    [setPage]
  );
  const handleSortingChange = useCallback(
    (field: ItemTableSortField) => {
      if (sortField === field) {
        // reverse sort on same field
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // set new sort field
        setSortField(field);
      }
      // set to first page in any case
      setPage(0);
    },
    [sortField, sortOrder, setSortField, setSortOrder]
  );

  const getRow = useCallback(
    (index: number) => {
      if (!filteredSortedItems || !filteredSortedItems[index]) {
        return (
          <TableRow
            sx={{
              height: 48,
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="items-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="items-td-name"
            ></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        );
      }
      const item = filteredSortedItems[index];
      return (
        <TableRow
          key={`${JSON.stringify(item)}`}
          sx={{
            height: 48,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="item-tr"
        >
          <TableCell component="th" scope="row" data-testid="item-td-name">
            {item.itemName}
          </TableCell>

          <TableCell align="right">{item.itemId}</TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              disabled={disabled || chosenCollectionItems.some(
                (ci) => ci.associatedItemId === Number(item.itemId)
              )}
              onClick={() => onClickAddItem(item)}
            >
              <ArrowForward />
            </Button>
          </TableCell>
        </TableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, chosenCollectionItems, filteredSortedItems]
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid>
          <Typography variant="h2">Items</Typography>
        </Grid>
        <Grid>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="Search items"
            placeholder="Search Items"
            testId="item-search"
          />
        </Grid>
      </Grid>
      <Grid sx={{ pl: 0, pr: 0, mt: 1 }}>
        {categoryListLoading && <Loader />}
        <TableContainer component={Paper} aria-label="Items Table">
          <Table aria-label="simple table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="col"
                  role="columnheader"
                  id="name-header"
                >
                  Name
                  <TableSortLabel
                    active={sortField === "itemName"}
                    direction={sortOrder}
                    onClick={() => handleSortingChange("itemName")}
                    data-testid="sort-by-item-name"
                  ></TableSortLabel>
                </TableCell>
                <TableCell
                  align="right"
                  component="th"
                  scope="col"
                  role="columnheader"
                  id="id-header"
                >
                  Id
                  <TableSortLabel
                    active={sortField === "itemId"}
                    direction={sortOrder}
                    onClick={() => handleSortingChange("itemId")}
                    data-testid="sort-by-item-id"
                  ></TableSortLabel>
                </TableCell>
                <TableCell
                  align="right"
                  component="th"
                  scope="col"
                  role="columnheader"
                  id="add-header"
                >
                  Add
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
          </Table>
          <Table>
            <TableBody>
              {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                getRow(page * pageSize + i)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={filteredSortedItems.length}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10]}
            aria-label="Pagination"
            data-testid="items-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "items-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </Grid>
    </>
  );
};

const ChosenCollectionItemsTable = ({
  chosenCollectionItems,
  onItemCollectionChange,
  disabled, 
}: {
  chosenCollectionItems: ValidItemCollectionItem[];
  onItemCollectionChange: (data: ValidItemCollectionItem[]) => void;
  disabled: boolean;
}) => {
  const { categoryList, getItemsByCategoryId } = useContext(DataContext);

  const [currentCat, setCurrentCat] = useState<number | null>(null);

  const categoryId = currentCat;

  const categoryItems = getItemsByCategoryId(String(categoryId));

  const handleIsChangeableChange =
    (item: ValidItemCollectionItem) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return; 
      const updatedItems = chosenCollectionItems.map((i) =>
        i.associatedItemId === item.associatedItemId
          ? { ...i, isChangeable: event.target.checked ? "Y" : "N" }
          : i
      );
      onItemCollectionChange(updatedItems);
    };

  const showCategoryDropdown = (isChangeable: string) => isChangeable === "Y";

  const paginatedItems = chosenCollectionItems;
  const [isMenuHovered, setIsMenuHovered] = useState(false);

  const handleMenuItemMouseOver = (categoryId: number) => {
    setIsMenuHovered(true);
    setCurrentCat(categoryId);
  };

  const handleMenuItemMouseLeave = () => {
    setIsMenuHovered(false);
    setCurrentCat(null);
  };
  const handleMoveItem = (index: number, direction: string) => {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    const updatedItems = [...chosenCollectionItems];
    [updatedItems[index], updatedItems[newIndex]] = [
      updatedItems[newIndex],
      updatedItems[index],
    ];
    onItemCollectionChange(updatedItems);
  };
  const handleRemoveFromSelectedItems = (item: CollectionItem) => {
    if (disabled) return; 
    const updatedListSelectedItems = chosenCollectionItems.filter(
      (i) => i !== item
    );
    onItemCollectionChange(updatedListSelectedItems);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: CollectionItem
  ) => {
    if (disabled) return; 
    const updatedCategoryId = Number(event.target.value);

    const updatedItems = chosenCollectionItems.map((i) =>
      i.associatedItemId === item.associatedItemId
        ? { ...i, associatedCategoryId: updatedCategoryId }
        : i
    );

    onItemCollectionChange(updatedItems);
  };

  return (
    <>
      <Grid container sx={{ ml: 0, mr: 0 }}>
        <Typography variant="h2">Selected Elements</Typography>
      </Grid>
      <Grid container sx={{ ml: 0, mr: 0, mt: 1 }}>
        <TableContainer component={Paper}>
          <Table>
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="col"
                  role="columnheader"
                  id="name-header"
                >
                  Name
                </TableCell>

                <TableCell
                  align="right"
                  scope="col"
                  role="columnheader"
                  id="id-header"
                >
                  Id
                </TableCell>

                <TableCell
                  align="right"
                  scope="col"
                  role="columnheader"
                  id="isChangeable-header"
                >
                  isChangeable
                </TableCell>

                <TableCell
                  align="center"
                  scope="col"
                  role="columnheader"
                  id="category-header"
                >
                  Category
                </TableCell>

                <TableCell
                  align="right"
                  scope="col"
                  role="columnheader"
                  id="delete-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {paginatedItems.map((item, index) => (
                <TableRow
                  key={item.associatedItemId}
                  role="row"
                  id={`row-${item.associatedItemId}`}
                >
                  <TableCell>{item.associatedItemName}</TableCell>
                  <TableCell align="right">{item.associatedItemId}</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={item.isChangeable === "Y"}
                      onChange={handleIsChangeableChange(item)}
                      disabled={disabled} 
                      color="primary"
                      size="small"
                      aria-label={`Changeability for ${item.associatedItemName}`}
                      aria-labelledby="isChangeable-header"
                    />
                  </TableCell>
                  <TableCell align="right">
                    {showCategoryDropdown(item.isChangeable) && (
                      <TextField
                        fullWidth
                        sx={{ width: "200px" }}
                        label="Category"
                        size="small"
                        value={item.associatedCategoryId || ""}
                        onChange={(event) => handleCategoryChange(event, item)}
                        aria-label={`Category for ${item.associatedItemName}`}
                        aria-labelledby="category-header"
                        select
                        required
                        disabled={disabled} 
                      >
                        {categoryList &&
                          categoryList.map((option) => (
                            <MenuItem
                              key={option.categoryId}
                              value={option.categoryId}
                              onMouseOver={() =>
                                handleMenuItemMouseOver(option.categoryId!)
                              }
                              onMouseLeave={handleMenuItemMouseLeave}
                            >
                              <Typography variant="body2">
                                {option.categoryName}
                              </Typography>
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ flexWrap: "nowrap" }}
                    >
                      {index !== 0 && (
                        <IconButton
                          color="primary"
                          onClick={() => handleMoveItem(index, "up")}
                          disabled={index === 0 || disabled} 
                          aria-label={`Move up ${item.associatedItemName}`}
                          aria-labelledby="move-header"
                          size="small"
                        >
                          <ArrowUpward />
                        </IconButton>
                      )}

                      {index !== chosenCollectionItems.length - 1 ? (
                        <IconButton
                          color="primary"
                          onClick={() => handleMoveItem(index, "down")}
                          disabled={index === chosenCollectionItems.length - 1 || disabled} 
                          aria-label={`Move down ${item.associatedItemName}`}
                          aria-labelledby="move-header"
                          size="small"
                        >
                          <ArrowDownward />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{ visibility: "hidden" }}
                          onClick={() => handleMoveItem(index, "down")}
                          disabled={index === chosenCollectionItems.length - 1 || disabled} 
                          size="small"
                        >
                          <ArrowDownward />
                        </IconButton>
                      )}
                      <IconButton
                        sx={{ color: "#DA291C", pl: 2 }}
                        onClick={() => handleRemoveFromSelectedItems(item)}
                        disabled={chosenCollectionItems.length === 0 || disabled} 
                        aria-label={`Delete ${item.associatedItemName}`}
                        aria-labelledby="delete-header"
                        size="small"
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isMenuHovered && (
            <>
              <Grid container mobile={12}>
                <IconButton
                  onClick={() => setIsMenuHovered(false)}
                  aria-label="Close Category Menu"
                >
                  <CloseOutlined />
                </IconButton>
              </Grid>
              <Grid
                container
                component={MenuList}
                sx={{
                  maxHeight: "218px",
                  overflowY: "auto",
                  pb: 1,
                }}
              >
                {categoryItems?.map((item) => (
                  <Grid mobile={6}>
                    <MenuItem dense>
                      <ListItemText>{item.itemName}</ListItemText>
                      <Typography
                        variant="body2"
                        sx={{ wordWrap: "break-word", overflow: "hidden" }}
                      >
                        {item.itemId}
                      </Typography>
                    </MenuItem>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </TableContainer>
      </Grid>
    </>
  );
};

const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
