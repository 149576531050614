import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { styled, useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useAxios } from "../axios-provider";
import { AxiosError } from "axios";
import { RoleContext } from "../role-provider";
import { useMutation } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { saveFoodPromotion } from "../data/dashboard";
import { ApiError } from "../utils";
import { components } from "../data/dashboard.types";

type EditFoodPromoModalProps = {
  onSaveSuccess: () => void;
  onCancel?: () => void;
  language?: string | null;
  selectedFoodPromo?: FoodPromotionData | null;
};
type FoodPromotionRequest = components["schemas"]["FoodPromotionRequest"];
type FoodPromotionData = components["schemas"]["FoodPromotionData"];

export const EditFoodPromoModal = ({
  onSaveSuccess,
  onCancel,
  selectedFoodPromo,
}: EditFoodPromoModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, isReaderRole, selectedRole } =
    useContext(RoleContext);
  const theme = useTheme();
  const [apiError, setApiError] = useState<string | null>(null);
  //Settting the start and end date to the existing start and end date by default Platform 15170 start amanjare
  const [startDate, setStartDate] = React.useState<Dayjs | null>(selectedFoodPromo?.startDate ? dayjs(selectedFoodPromo.startDate) : dayjs());
  const [endDate, setEndDate] = React.useState<Dayjs | null>(selectedFoodPromo?.endDate ? dayjs(selectedFoodPromo.endDate) : dayjs());
//Settting the start and end date to the existing start and end date by default Platform 15170 end amanjare
  const saveFoodPromotionRequest = saveFoodPromotion(apiClient);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FoodPromotionRequest>({
    defaultValues: {
      projectName: selectedFoodPromo?.projectName,
    },
  });

  const onSubmit = (data: FoodPromotionRequest) => {
    mutate(data);
  };

  const { mutate } = useMutation(
    (data: FoodPromotionRequest) => {
      const metaData = {
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        foodPromotionId: selectedFoodPromo?.foodPromotionId!,
      };
      const updatedData = {
        ...data,
        startDate: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        endDate: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      };
      return saveFoodPromotionRequest(updatedData, metaData);
    },
    {
      onMutate: () => saveFoodPromotionRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setApiError(
            "There was an error updating food promotion. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setApiError(errorMessage.message);
        } else {
          setApiError(String(error));
        }
      },
    }
  );

  return (
    <Dialog open={true} onClose={onCancel}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="create-food-promo-form"
      >
        <StyledRelativeContainer>
          <DialogTitle id="edit-food-promo-title">
            Edit Food Promotion
          </DialogTitle>
          <DialogContent>
            {apiError && (
              <Grid marginLeft={3} marginTop={3}>
                <Alert variant="outlined" severity="error">
                  {apiError}
                </Alert>
              </Grid>
            )}
            <Grid container spacing={2} mobile={12} padding={4}>
              <Grid item mobile={12} padding={4} paddingTop={0}>
                <StyledTextField
                  fullWidth
                  sx={{ width: "100%" }}
                  label="Project Name*"
                  {...register("projectName", { required: true })}
                  SelectProps={{ native: true }}
                  disabled={!!isReaderRole}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.projectName}
                  helperText={errors.projectName && "Project Name is required"}
                  data-testid="project-name-select"
                ></StyledTextField>
                <Grid item mobile={12} paddingTop={2}>
                  <DatePicker
                    sx={{
                      "> .Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    label="Start Date"
                    {...register("startDate", { required: "Start date is required" })}
                    value={dayjs(selectedFoodPromo?.startDate)}
                    minDate={dayjs().add(1, "day")}
                    aria-label="date picker"
                    onChange={(newValue) => setStartDate(newValue)}
                    autoFocus={true}
                  />
                  <DatePicker
                    sx={{
                      "> .Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                      },
                      marginLeft: 4,
                    }}
                    label="End Date"
                    {...register("endDate", { required: "End date is required" })}
                    value={dayjs(selectedFoodPromo?.endDate)}
                    minDate={startDate?.add(1,"day")} 
                    aria-label="date picker"
                    onChange={(newValue) => setEndDate(newValue)}
                    autoFocus={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <StyledActionButton variant="contained" onClick={onCancel}>
              Cancel
            </StyledActionButton>
            <Button type="submit" variant="contained">
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </StyledRelativeContainer>
      </form>
    </Dialog>
  );
};
const StyledActionButton = styled(Button)({
  backgroundColor: "#DA291C",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledRelativeContainer = styled(Grid)({
  width: "70vw",
  margin: 0,
  position: "relative",
});
