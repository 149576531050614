import React, { createContext, useMemo, useContext, useRef,useEffect, useState } from "react";
import { useOutlet } from "react-router-dom";
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { AuthContext } from "./auth-provider";
import {
  expireRefreshToken,
  refreshToken as postRefreshToken,
} from "./data/auth";
import { ApiError } from "./utils";
import {  resetInactivityTimeout, setupInactivityTracking } from "./data/inactivityTimeout"; // Import the inactivity tracking functions

interface AxiosProviderInterface {
  apiClient: AxiosInstance | undefined;
}


export const AxiosContext = createContext<AxiosProviderInterface>({
  apiClient: undefined,
});
//Header is added and withCredentials is set to true to the request to send the csrf token for refreshToken request and Logout API call Platform 14222 amanjare
function createClient(token :string | null): AxiosInstance {
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'X-XSRF-TOKEN': token, // Replace 'your-token-here' with the actual token value
    },
    withCredentials: true
  });
}
//csrfToken ,setCsrfToken is added in the AxiosProvider to get the csrfToken from the AuthProvider Platform 14222 amanjare
export const AxiosProvider = () => {
  const outlet = useOutlet();
  const {
    accessToken,
    refreshToken,
    csrfToken,
    eId,
    setAccessToken,
    setRefreshToken,
    setCsrfToken,
    logout,
  } = useContext(AuthContext);

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const isRefreshTokenRequestSent = useRef(false);
  const isLogoutInProgress = useRef(false); // Add a flag to prevent multiple logout calls
  const doLogoutRef = useRef<() => Promise<void>>(); // Ref to store the doLogout function

  let subscribers: Array<(value: string | null) => void> = [];

  const onTokenRefreshed = (accessToken: string | null) => {
    subscribers.forEach((callback) => callback(accessToken));
    subscribers = [];
  };

  const addSubscriber = (callback: (value: string | null) => void) => {
    subscribers.push(callback);
  };

  // const doLogout = async () => {
  //   try {
  //     //csrfToken is added in the header for the logout API call Platform 14222 amanjare
  //     await expireRefreshToken(createClient(csrfToken))({
  //       token: accessToken!,
  //     });
  //   } catch (e) {
  //     // swallow error as it means token is already expired
  //   } finally {
  //     // log user out whether or not token expire succeeded
  //     return logout();
  //   }
  // };

  // const doLogout = async () => {
  //   if (isLoggedOut) return; // Prevent multiple logout calls

  //   try {
  //     //csrfToken is added in the header for the logout API call Platform 14222 amanjare
  //     await expireRefreshToken(createClient(csrfToken))({
  //       token: accessToken!,
  //     });
  //   } catch (e) {
  //     // swallow error as it means token is already expired
  //   } finally {
  //      // log user out whether or not token expire succeeded
  //     setIsLoggedOut(true);
  //     return logout();
  //   }
  // };
  const doLogout = async () => {
    if (isLoggedOut || isLogoutInProgress.current) return; // Prevent multiple logout calls

    isLogoutInProgress.current = true; // Mark logout as in progress

    try {
      await expireRefreshToken(createClient(csrfToken))({
        token: accessToken!,
      });
    } catch (e) {
      // Swallow error if token is already expired
    } finally {
      setIsLoggedOut(true);
      isLogoutInProgress.current = false; // Reset the flag after completion
      return logout();
    }
  };
   // Store the doLogout function in the ref
   doLogoutRef.current = doLogout;
  const doRefreshToken = async () => {
    if (!isRefreshTokenRequestSent.current) {
      isRefreshTokenRequestSent.current = true;
      try {
        if (!refreshToken) {
          throw new Error("Missing refresh token");
        }
        //csrfToken is added in the header for the refreshToken API call Platform 14222 amanjare
        const response = await postRefreshToken(createClient(csrfToken))({
          token: refreshToken,
          

        });
        if (response.data.refreshToken) {
          setRefreshToken(response.data.refreshToken);
        }
        if (response.data.accessToken) {
          setAccessToken(response.data.accessToken);
        }
        //initializing csrfToken in the AxiosProvider Platform 14222 amanjare
        if (response.data?.csrfToken?.token) {
          setCsrfToken(response.data.csrfToken.token);
       }
        // process queued requests with new token
        onTokenRefreshed(response.data.accessToken!);
        isRefreshTokenRequestSent.current = false;
       // resetInactivityTimeout(doLogout); // Reset inactivity timeout on successful token refresh
       resetInactivityTimeout(doLogoutRef.current!, Number(process.env.REACT_APP_INACTIVITY_LIMIT )); // Reset inactivity timeout on successful token refresh
 
       return response.data.accessToken;
      } catch (error) {
        // token refresh error - reject all queued promises
        onTokenRefreshed(null);
        isRefreshTokenRequestSent.current = false;
        return Promise.reject(error);
      }
    }
  };

  const apiClient: AxiosInstance = useMemo(() => {
    //providing null csrfToken to avoid sending csrfToken from Get Request Platform 14222 amanjare
    const client = createClient(null);

    if (!accessToken) {
      return client;
    }

    const setAuthorizationHeader = (config: InternalAxiosRequestConfig) => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      if (eId) {
        config.headers["eId"] = eId;
      }
    //added csrf token in header for all request except get request start Platform 14222 amanjare start
      if(config.method!=='get' && csrfToken){
      
        config.headers["X-XSRF-TOKEN"] = csrfToken;
       
      }
     config.withCredentials =true
      //added csrf token in header for all request except get request start Platform 14222 amanjare end
     
      return config;
    };

   
    client.interceptors.request.use(setAuthorizationHeader);
   
    client.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error) => {
        const originalRequest = error.config;
        if (
          isRefreshTokenRequestSent.current &&
          originalRequest.method === "get"
        ) {
          // token refresh in progress - queue request for when refresh completes
          // only queue for retry if method is GET
          try {
            const token = await new Promise((resolve, reject) => {
              addSubscriber((refreshedAccessToken) => {
                if (refreshedAccessToken) {
                  resolve(refreshedAccessToken);
                } else {
                  reject();
                }
              });
            });
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
           originalRequest.headers["X-XSRF-TOKEN"] = csrfToken;
            return axios(originalRequest);
          } catch {
            return Promise.reject(error);
          }
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          // handle 401 with "Invalid Role Id" error
          // (occurs when request is sent with roleId not belonging to user)
          const apiError: ApiError = error.response.data as ApiError;
          if (apiError.message.toLowerCase().includes("invalid role id")) {
            return doLogout();
          }
          try {
            const newAccessToken = await doRefreshToken();

            if (originalRequest.method === "get") {
              // only retry automatically if method is GET
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              return axios(originalRequest);
            }
          } catch (e) {
            // token refresh failed - logout user
            return doLogout();
          }
        }
        return Promise.reject(error);
      }
    );

    return client;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, eId]);

  const value = useMemo(
    () => ({
      apiClient,
    }),
    [apiClient]
  );
  // Setup inactivity tracking on component mount
  // useEffect(() => {
  //   if (!isLoggedOut) {
  //     resetInactivityTimeout(doLogout);
  //   }
  // }, [doLogout, isLoggedOut]);
  useEffect(() => {
    if (!isLoggedOut) {
      resetInactivityTimeout(doLogoutRef.current!, Number(process.env.REACT_APP_INACTIVITY_LIMIT) ); // Pass the dynamic inactivity limit
    }
  }, [isLoggedOut]);

  useEffect(() => {
    const cleanup = setupInactivityTracking(doLogoutRef.current!, Number(process.env.REACT_APP_INACTIVITY_LIMIT )); // Pass the dynamic inactivity limit
    return cleanup;
  }, []);

  return <AxiosContext.Provider value={value}>{outlet}</AxiosContext.Provider>;
};

export const useAxios = (): {
  apiClient: AxiosInstance;
} => {
  const { apiClient } = useContext(AxiosContext);
  if (!apiClient) {
    throw new Error("useAxios must be used within an AxiosProvider");
  }
  //added withCredentials to true instance Platform 14222 amanjare
  apiClient.defaults.withCredentials = true;
  return { apiClient };
};
