import { faker } from "@faker-js/faker";
import moment from "moment";
import { AxiosHeaders, AxiosError } from "axios";
import {
  realGetAccessToken,
  realGasAuthenticationToken,
  realRefreshToken,
  realExpireRefreshToken,
} from "../api/auth";
import { getStoredValue } from "../../utils";

const ERROR_EID = "er4044me";
//csrfToken is added in accessToken,gasAuthenticationToken,refreshToken api response payload Platform 14222 amanjare
function generateFakeJwt() {
  const payload = {
    sub: `eg${faker.datatype.number({ min: 100000, max: 999999 })}`,
    iat: moment().unix(),
    exp: moment().add(30, "minutes").unix(),
  };
  // Base64 encode the header, payload, and add a fake signature
  const headerBase64 = btoa(JSON.stringify({ alg: "HS256" }));
  const payloadBase64 = btoa(JSON.stringify(payload));
  const fakeSignature = faker.datatype.string(43); // A random string, not a real signature
  return `${headerBase64}.${payloadBase64}.${fakeSignature}`;
}

export const mockGetAccessToken: ReturnType<
  typeof realGetAccessToken
> = async ({ eId }) => {
  if (eId === ERROR_EID) {
    // Mimic a 403 error response for ERROR_EID
    const error: AxiosError = {
      isAxiosError: true,
      code: "403",
      name: "AxiosError",
      message: "Invalid CORS request",
      response: {
        status: 403,
        statusText: "Forbidden",
        headers: {},
        config: {
          headers: new AxiosHeaders(),
        },
        data: "Invalid CORS request",
      },
      toJSON: () => ({}),
    };

    throw error;
  }
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      code: 2000,
      type: "Success",
      message: "The call was successful.",
      refreshToken: faker.datatype.uuid(),
      accessToken: generateFakeJwt(),
      csrfToken: faker.datatype.uuid(),
      tokenType: "Bearer",
      expiresRefreshToken: "100",
      expiresAccessToken: "10",
      eid: eId,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockRefreshToken: ReturnType<typeof realRefreshToken> = async ({
  token,
}) => {
  const eId = getStoredValue("eId");
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      code: 2000,
      type: "Success",
      message: "The call was successful.",
      refreshToken: faker.datatype.uuid(),
      accessToken: generateFakeJwt(),
      csrfToken: faker.datatype.uuid(),
      tokenType: "Bearer",
      expiresRefreshToken: "100",
      expiresAccessToken: "10",
      eid: eId ? eId : "mock-reader-eid",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGasAuthenticationToken: ReturnType<
  typeof realGasAuthenticationToken
> = async ({ code, codeVerifier }) => {
  const eId = getStoredValue("eId");
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      refreshToken: faker.datatype.uuid(),
      accessToken: generateFakeJwt(),
      tokenType: "Bearer",
      expiresRefreshToken: "100",
      expiresAccessToken: "10",
      csrfToken: faker.datatype.uuid(),
      eid: eId ? eId : "mock-reader-eid",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockExpireRefreshToken: ReturnType<
  typeof realExpireRefreshToken
> = async ({ token }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Token Deleted successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

