import {
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Tab,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Tabs,
  Alert,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useItem } from "../pages/item";
import { RoleContext } from "../role-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getAllMutexGroups,
  getAllOptionalItems,
  getSelectedElementsMapping,
  saveHasComponentApiCall,
  saveItemProductMapping,
} from "../data/items";
import { useAxios } from "../axios-provider";
import { useNavigate } from "react-router-dom";
import { DeleteOutline, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Loader } from "./loader/Loader";
import {
  ApiError,
  DisplayableElementMapping,
  ValidMutexGroup,
  ValidOptionalItem,
  ValidSelectedElementMapping,
  formatElementMappingForDisplay,
  isElementMapping,
  isMutexGroup,
  isOptionalItem,
} from "../utils";
import { Controller, useForm } from "react-hook-form";
import { MutexGroupModal } from "./MutexGroupModal";
import { StyledDangerButton, UnsavedChangesModal } from "./UnsavedChangesModal";
import { SelectMutexTable } from "./SelectMutexTable";
import { StyledDashboardTableHead } from "../pages/dashboard";
import { FixedHeightTableLoader } from "./FixedHeightLoader";
import { SearchInput } from "./SearchInput";
import { SelectProductsTable } from "./SelectProductsTable";
import { SelectOptionalItemsTable } from "./SelectOptionalItemsTable";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import {
  localizedNumStringOrEmpty,
  localizedNumStringOrNA,
  standardizedNumStringOrNA,
  standardizedNumStringOrPlaceholder,
} from "../util/number-localization";

type ElementMappingUpdate = {
  elementId: number;
  quantity: string;
  displayOrder: number;
};

type ElementMappingForm = {
  hasComponents: boolean;
  mappedElements: DisplayableElementMapping[];
};
type DuplicateElement = {
  elementName: string;
  mutexName: string;
  mutexId: number;
}
function formatElementUpdateList(
  mappedElements: DisplayableElementMapping[],
  marketCode: string
): ElementMappingUpdate[] {
  return mappedElements.map((me: DisplayableElementMapping, index: number) => {
    return {
      elementId: me.elementId,
      quantity: standardizedNumStringOrPlaceholder(me.qty, marketCode, "0"),
      displayOrder: index + 1,
    };
  });
}

export const ItemProductMapping = () => {
  const navigate = useNavigate();
//removed collectionItems and footers for Platform 15485 (added-15169) 
  const { itemId, editable, attributes, hasComponents, isLive } = useItem();
  const {
    selectedCountry,
    selectedRole,
    isReaderRole,
    isItemLevelMarket,
    defaultLanguage,
  } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState<number>(isItemLevelMarket ? 1 : 0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [customError, setCustomError] = useState<string | null>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [duplicateError, setDuplicateError] = useState<string[]>([]);

  const isPrefetched = useRef(false);

  const handleLeaveNavigation = () => {
    reset({
      hasComponents,
      mappedElements,
    });
    setShowUnsavedChangesModal(false);
    setDuplicateError([]);
  };
  const mappedElementsQuery = useCustomQuery(
    ["getSelectedElementsMapping", { itemId }],
    () =>
      getSelectedElementsMapping(apiClient)({
        itemId: String(itemId),
        countryCode: selectedCountry!,
      })
  );
  const mappedElements = useMemo(() => {
    if (mappedElementsQuery.data?.data.elementList === undefined) {
      return [];
    }
    const validMappedElements =
      mappedElementsQuery.data?.data.elementList?.filter(
        (
          maybeMappedElement
        ): maybeMappedElement is ValidSelectedElementMapping => {
          return isElementMapping(maybeMappedElement) === true;
        }
      );
    return validMappedElements.map((mappedElement) =>
      formatElementMappingForDisplay(mappedElement, selectedCountry!)
    );
  }, [mappedElementsQuery.data, selectedCountry]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<ElementMappingForm>({
    defaultValues: {
      hasComponents,
      mappedElements,
    },
  });
  useEffect(() => {
    setValue("mappedElements", mappedElements, { shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedElements]);

  const editableHasComponents = watch("hasComponents");
  const editableMappedElements = watch("mappedElements");
//changes done for platfomr 15485 start amanjare
  const saveHasComponentRequest = saveHasComponentApiCall(apiClient);
  const saveHasComponent = useCallback(
    (hasComponentsValue: boolean) => {
      const finalAttributes = attributes.filter(attr => attr.keyName === "has_components").reduce((obj, attr) => {
        
          attr.value = hasComponentsValue ? "Yes" : "No";
     
        return attr;
      }, {});
      //added parameters for footers and collectionItems in Request Body while changing has No component to Has Component Platform 15169  
      return saveHasComponentRequest(
        
          finalAttributes
          
        ,
        {
          itemId,
          countryCode: selectedCountry!,
          roleId: selectedRole!,
          languageCode: defaultLanguage!,
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    attributes
  );
//changes done for platfomr 15485 end amanjare
  const saveItemProductMappingRequest = saveItemProductMapping(apiClient);
  const {
    mutate: doSaveItemProductMapping,
    isLoading: isSaveItemProductMappingLoading,
  } = useMutation(
    async (data: ElementMappingForm) => {
      if (data.hasComponents !== hasComponents) {
        if (data.hasComponents === false) {
          // has_components value changed to false - save that first
          await saveHasComponent(false);
          // then save item-product mapping with empty datalist
          return saveItemProductMappingRequest(
            {
              itemId: itemId,
              dataList: [],
            },
            {
              countryCode: selectedCountry!,
              roleId: String(selectedRole)!,
            }
          );
        } else {
          // has_components value changed to true - save that change first
          await saveHasComponent(true);
          // then save the item-product mapping
          return saveItemProductMappingRequest(
            {
              itemId: itemId,
              dataList: formatElementUpdateList(
                data.mappedElements,
                selectedCountry!
              ),
            },
            {
              countryCode: selectedCountry!,
              roleId: String(selectedRole)!,
            }
          );
        }
      } else {
        // has_component value has not changed. simply save
        return saveItemProductMappingRequest(
          {
            itemId: itemId,
            dataList: formatElementUpdateList(
              data.mappedElements,
              selectedCountry!
            ),
          },
          {
            countryCode: selectedCountry!,
            roleId: String(selectedRole)!,
          }
        );
      }
    },
    {
      onMutate: () => saveItemProductMappingRequest,
      onSuccess: () => {
        setSuccessMessage(`Mappings for Item ${itemId} saved successfully`);
        setTimeout(() => {
          queryClient.invalidateQueries([
            "getItemMarketingInformation",
            { itemId },
          ]);
          queryClient.invalidateQueries([
            "getSelectedElementsMapping",
            { itemId },
          ]);
          queryClient.invalidateQueries(["getProductsByItemId", { itemId }]);
          queryClient.invalidateQueries(["getAllOptionalItems", { itemId }]);
          queryClient.invalidateQueries(["getAllMutexGroups", { itemId }]);
          queryClient.invalidateQueries([
            "getIngredientsByItemAndProductIds",
            { itemId },
          ]);
          queryClient.invalidateQueries(["item/getCompareCurrent", { itemId }]);
          queryClient.invalidateQueries(["getProductsByItemID", { itemId }]);
          queryClient.invalidateQueries([
            "getNutritionFactsByProductIds",
            { itemId },
          ]);
        }, 3000);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const isWorking = useMemo(
    () => mappedElementsQuery.isFetching || isSaveItemProductMappingLoading,
    [mappedElementsQuery.isFetching, isSaveItemProductMappingLoading]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setValue("hasComponents", newValue === "true", { shouldDirty: true });
  };

  const onSubmit = (data: ElementMappingForm) => {
    setCustomError(null);
    setDuplicateError([]);

    const optionalElementIds = editableMappedElements
      .filter((element) => element.elementType === "Optional Item")
      .map((element) => element.elementId);

    const productElementIds = editableMappedElements
      .filter((element) => element.elementType === "Product")
      .map((element) => element.elementId);
    const mutexElementIds = editableMappedElements
      .filter((element) => element.elementType === "Mutex" && typeof element.elementId === 'number') // Filter out undefined elementIds
      .map((element) => element.elementId as number);

    // Filter and find the selected mutex groups based on mutexElementIds
    const selectedMutexGroups = allMutexGroupsQuery.data?.data?.dataList?.filter(mutex =>
      typeof mutex?.mutexId === 'number' && mutexElementIds.includes(mutex.mutexId as number)
    ) || [];

    const matchedElementIds = selectedMutexGroups.map(mutexGroup => {
      const mutexName = mutexGroup.mutexName;
      const mutexId = mutexGroup.mutexId;
      const elementIds: string | undefined = mutexGroup.elementIds;
      const elementName: string | undefined = mutexGroup.elementName;

      if (elementIds && elementName) {
        const parsedElementIds = elementIds.split(',').map(Number);
        const parsedElementNames = elementName.split(',').filter(Boolean);

        return {
          elementIds: parsedElementIds,
          elementName: parsedElementNames,
          mutexName,
          mutexId
        };
      } else {
        // Define empty arrays for elementIds and elementNames if either elementIds or elementName is missing
        return {
          elementIds: [],
          elementName: [],
          mutexName,
          mutexId
        };
      }
    }).filter(group => group.elementIds.length > 0);

    //  Filter based on mutexId match with mutexElementIds.elementId
    const filteredGroups = matchedElementIds.filter(group =>
      typeof group.mutexId === 'number' && mutexElementIds.includes(group.mutexId)
    );

    //  Find duplicate elementIds
    const elementIdCount: { [key: number]: number } = filteredGroups.flatMap(group => group.elementIds)
      .reduce((acc: { [key: number]: number }, id: number) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      }, {});

    const duplicateElementIds = Object.keys(elementIdCount).filter(id => elementIdCount[Number(id)] > 1).map(Number);

    //  Get corresponding elementNames for duplicate elementIds
    const duplicateElementNamesWithMutex: DuplicateElement[] = [];
    mutexElementIds.forEach(mutexId => {
      // Find groups where mutexId matches
      const groupsWithMatchingMutexId = filteredGroups.filter(group => group.mutexId === mutexId);

      // Process each group with matching mutexId
      groupsWithMatchingMutexId.forEach(group => {
        // Map elementIds to duplicate names
        const duplicateNames = group.elementIds.map((id, index) => {
          if (duplicateElementIds.includes(id)) {
            return { elementName: group.elementName[index], mutexName: group.mutexName || "", mutexId };
          }
          return null;
        }).filter((name): name is DuplicateElement => name !== null);

        // Merge duplicate names into existing entries or push new entries
        duplicateNames.forEach(name => {
          const existingEntry = duplicateElementNamesWithMutex.find(entry => entry.mutexId === name.mutexId);
          if (existingEntry) {
            const elements = existingEntry.elementName.split(',').map(e => e.trim());
            const uniqueElements = [...new Set([...elements, name.elementName])]; // Ensure uniqueness
            existingEntry.elementName = uniqueElements.join(', ');
          } else {
            duplicateElementNamesWithMutex.push(name);
          }
        });
      });
    });

    const duplicateProductIds = matchedElementIds.filter(group =>
      group.elementIds.some(id => productElementIds.includes(id)));
    const duplicateOptionalIds = matchedElementIds.filter(group =>
      group.elementIds.some(id => optionalElementIds.includes(id)));
    const duplicateIds = [...duplicateProductIds, ...duplicateOptionalIds];

    if (data.hasComponents && data.mappedElements.length === 0) {
      // disallow saving hasComponent = true with zero mapped elements
      setCustomError(
        "This item does not have any components associated with it."
      );
    }
    if ((data.hasComponents && duplicateIds.length > 0) || (data.hasComponents && duplicateElementNamesWithMutex.length > 0)) {
      // disallow saving hasComponent with duplicate id product/optional mapped with selected Mutex id
      const duplicateElementNames = editableMappedElements.filter(element => {
        return matchedElementIds.some(matchedId => matchedId.elementIds.includes(element.elementId));
      });

      const entriesToProcess = duplicateElementNamesWithMutex.slice(1); // Remove the first element
      const duplicateMutexNameMessages: string[] = [];
      for (const name of entriesToProcess) {
        const existingEntry = duplicateElementNamesWithMutex.find(
          (entry) => entry.mutexId === name.mutexId
        );
        if (existingEntry) {
          const elements = existingEntry.elementName
            .split(",")
            .map((e) => e.trim());
          const uniqueElements = [...new Set(elements)];
          duplicateMutexNameMessages.push(
            `Elements '${uniqueElements}' in Mutex Group '${name.mutexName}' are repeated please remove the multiple associations.`
          );

        } else {
          duplicateElementNamesWithMutex.push(name);
        }
      }


      const matchedNames = matchedElementIds.map((matchedId) => {
        const elementNames = duplicateElementNames
          .filter((element) => matchedId.elementIds.includes(element.elementId))
          .map((element) => element.elementName)
          .join(",");
        const mutexName = `${matchedId.mutexName} `;
        return { elementNames, mutexName };
      });

      const additionalMessages = matchedNames.filter(({ elementNames }) => elementNames.trim() !== '')
        .map(({ elementNames, mutexName }) =>
          `Elements '${elementNames}' in Mutex Group '${mutexName}' are repeated please remove the multiple associations.`
        );

      duplicateMutexNameMessages.push(...additionalMessages);

      if (duplicateMutexNameMessages.length > 0) {
        setDuplicateError(duplicateMutexNameMessages);
      }
    }
    else {
            doSaveItemProductMapping(data);
    }
  };
 
  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setActiveTab(newTab);
  };
  const [editMutexDialogOpen, setEditMutexDialogOpen] = useState(false);
  const [editableMutexId, setEditableMutexId] = useState<number | null>(null);
  const [editableMutexName, setEditableMutexName] = useState<string>("");

  const handleClickNewMutex = () => {
    setEditableMutexId(null);
    setEditableMutexName("");
    setEditMutexDialogOpen(true);
  };
  const handleClickEditMutex = (mutex: { id: number | null; name: string }) => {
    setEditableMutexId(mutex.id);
    setEditableMutexName(mutex.name);
    setEditMutexDialogOpen(true);
  };

  const handleMutexDialogClose = () => {
    setEditMutexDialogOpen(false);
  };

  const onSaveMutexSuccess = () => {
    setEditMutexDialogOpen(false);
    queryClient.invalidateQueries(["getAllMutexGroups", { itemId }]);
    queryClient.invalidateQueries(["getOptionalItemDetailsByMutexId"]);
    queryClient.invalidateQueries(["getProductDetailsByMutexId"]);
    setSuccessMessage("Mutex group was saved successfully");
  };
  const onSaveMutexError = (error: string) => {
    setEditMutexDialogOpen(false);
    setErrorMessage(error);
  };

  const handleAddProduct = (product: { id: number; name: string }) => {
    const { id, name } = product;
    const newMappedElement: DisplayableElementMapping = {
      elementId: id,
      elementName: name,
      qty: localizedNumStringOrEmpty("1.0", selectedCountry!),
      elementType: "Product",
    };
    setValue("mappedElements", [...editableMappedElements, newMappedElement], {
      shouldDirty: true,
    });
  };

  const handleAddOptionalItem = (optionalItem: {
    id: number;
    name: string;
  }) => {
    const { id, name } = optionalItem;
    const newMappedElement: DisplayableElementMapping = {
      elementId: id,
      elementName: name,
      qty: localizedNumStringOrEmpty("1.0", selectedCountry!),
      elementType: "Optional Item",
    };
    setValue("mappedElements", [...editableMappedElements, newMappedElement], {
      shouldDirty: true,
    });
  };

  const handleAddMutex = (mutex: { id: number; name: string }) => {
    const { id, name } = mutex;
    const newMappedElement: DisplayableElementMapping = {
      elementId: id,
      elementName: name,
      qty: localizedNumStringOrEmpty("1.0", selectedCountry!),
      elementType: "Mutex",
    };
    setValue("mappedElements", [...editableMappedElements, newMappedElement], {
      shouldDirty: true,
    });
  };

  const handleMoveItem = useCallback(
    (index: number, direction: "up" | "down") => {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const updatedElements = [...editableMappedElements];
      [updatedElements[index], updatedElements[newIndex]] = [
        updatedElements[newIndex],
        updatedElements[index],
      ];
      setValue("mappedElements", updatedElements, { shouldDirty: true });
    },
    [setValue, editableMappedElements]
  );

  const handleRemoveFromMapped = useCallback(
    (element: DisplayableElementMapping) => {
      const updatedElements = editableMappedElements.filter(
        (me) => me.elementId !== element.elementId
      );
      setValue("mappedElements", updatedElements, { shouldDirty: true });
    },
    [setValue, editableMappedElements]
  );

  const handleErrorAlertClose = useCallback(() => {
    setErrorMessage(null);
  }, []);

  useEffect(() => {
    if (!isPrefetched.current) {
      queryClient.prefetchQuery(["getAllOptionalItems", { itemId }], () =>
        getAllOptionalItems(apiClient)({
          countryCode: selectedCountry!,
          itemId: String(itemId),
        })
      );

      queryClient.prefetchQuery(["getAllMutexGroups", { itemId }], () =>
        getAllMutexGroups(apiClient)({
          countryCode: selectedCountry!,
          itemId: itemId,
        })
      );
      isPrefetched.current = true;
    }
  }, [itemId, selectedCountry, queryClient, apiClient]);
  const allOptionalItemsQuery = useCustomQuery(
    ["getAllOptionalItems", { itemId }],
    () =>
      getAllOptionalItems(apiClient)({
        countryCode: selectedCountry!,
        itemId: String(itemId),
      })
  );
  const allOptionalItems = useMemo(() => {
    if (allOptionalItemsQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validOptionalItems = allOptionalItemsQuery.data?.data.dataList.filter(
      (maybeOptionalItem): maybeOptionalItem is ValidOptionalItem => {
        return isOptionalItem(maybeOptionalItem) === true;
      }
    );
    return validOptionalItems;
  }, [allOptionalItemsQuery.data]);
  const isFetchingOptionalItems = useMemo(
    () => allOptionalItemsQuery.isFetching,
    [allOptionalItemsQuery.isFetching]
  );
  const isSuccessOptionalItems = useMemo(
    () => allOptionalItemsQuery.isSuccess,
    [allOptionalItemsQuery.isSuccess]
  );

  const allMutexGroupsQuery = useCustomQuery(
    ["getAllMutexGroups", { itemId }],
    () =>
      getAllMutexGroups(apiClient)({
        countryCode: selectedCountry!,
        itemId: itemId,
      })
  );
  const allMutexGroups = useMemo(() => {
    if (allMutexGroupsQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validMutexGroups = allMutexGroupsQuery.data?.data.dataList.filter(
      (maybeMutexGroup): maybeMutexGroup is ValidMutexGroup => {
        return isMutexGroup(maybeMutexGroup) === true;
      }
    );
    return validMutexGroups;
  }, [allMutexGroupsQuery.data]);
  const isFetchingMutexGroup = useMemo(
    () => allMutexGroupsQuery.isFetching,
    [allMutexGroupsQuery.isFetching]
  );
  const isSuccessMutexGroup = useMemo(
    () => allMutexGroupsQuery.isSuccess,
    [allMutexGroupsQuery.isSuccess]
  );

  const isSuccess = useMemo(
    () => mappedElementsQuery.isSuccess,
    [mappedElementsQuery.isSuccess]
  );
  const isEmpty = useMemo(() => {
    return (
      !isWorking &&
      isSuccess &&
      editableMappedElements.length === 0 &&
      !isFetchingMutexGroup &&
      !isFetchingOptionalItems
    );
  }, [
    isWorking,
    isSuccess,
    editableMappedElements.length,
    isFetchingMutexGroup,
    isFetchingOptionalItems,
  ]);
  const isFetchingAll = useMemo(
    () => isWorking || isFetchingMutexGroup || isFetchingOptionalItems,
    [isFetchingMutexGroup, isFetchingOptionalItems, isWorking]
  );
  const filteredElementIds = useMemo(() => {
    return editableMappedElements.map((id) => id.elementId);
  }, [editableMappedElements]);

  return (
    <StyledRelativeContainer>
      {isWorking && <Loader />}
      {!isWorking && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="item-mapped-elements-table"
        >
          <Grid container spacing={1} sx={{ m: 0 }}>
            <Grid mobile={12} sx={{ my: 3 }}>
              <Grid
                container
                mobile={12}
                sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h2">Item Product Mapping</Typography>
                <StyledSecondaryButton
                  variant="contained"
                  disabled={!isLive}
                  onClick={() => {
                    navigate(`/items/${itemId}/compare-with-live`);
                  }}
                >
                  Compare with live
                </StyledSecondaryButton>
              </Grid>
            </Grid>
            <StyledTopActionPanel container mobile={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="controlled-radio-buttons-group"
                  value={editableHasComponents}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    disabled={!!isReaderRole || !editable}
                    label="Has Component"
                    data-testid="item-has-component"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    disabled={!!isReaderRole || !editable}
                    label="Has no Component"
                    data-testid="item-has-no-component"
                  />
                </RadioGroup>
              </FormControl>
            </StyledTopActionPanel>
            {editableHasComponents === true && (
              <Grid container mobile={12}>
                <>
                  {isFetchingAll && (
                    <Grid
                      mobile={12}
                      spacing={2}
                      container
                      justifyContent="center"
                      sx={{ display: "flex", width: "100vw" }}
                    >
                      <FixedHeightTableLoader pageSize={6} isEmpty={isEmpty}>
                        <Loader />
                      </FixedHeightTableLoader>
                    </Grid>
                  )}
                  <Grid mobile={12} spacing={2} container>
                    {!isFetchingAll && (
                      <>
                        <Grid
                          mobile={6}
                          container
                          sx={{ justifyContent: "space-between", pr: 1, pl: 1 }}
                          spacing={0}
                        >
                          <Tabs
                            TabIndicatorProps={{
                              sx: { backgroundColor: "#FFBC0D" },
                            }}
                            value={activeTab}
                            onChange={handleTabChange}
                            role="tablist"
                          >
                            {!isItemLevelMarket && (
                              <StyledTab
                                label="Products"
                                data-testid="mappable-products"
                                value={0}
                              />
                            )}
                            <StyledTab
                              label="Optional Items"
                              data-testid="mappable-optional-items"
                              value={1}
                            />
                            <StyledTab
                              label="Mutex"
                              data-testid="mappable-mutex-groups"
                              value={2}
                            />
                          </Tabs>
                          <SearchInput
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search"
                            testId="mappable-element-search-input"
                            id="mappable-element-search-input"
                            aria-label="Search by Name"
                          />
                        </Grid>
                        <Grid mobile={6}>
                          <Typography variant="h4">
                            Selected Products/Optional Items/Mutex
                          </Typography>
                        </Grid>

                        <Grid mobile={6}>
                          <div>
                            {activeTab === 0 && !isItemLevelMarket && (
                              <Typography component="div" role="tabpanel">
                                <SelectProductsTable
                                  selectedProductIds={editableMappedElements
                                    .filter((me: DisplayableElementMapping) => {
                                      return me.elementType === "Product";
                                    })
                                    .map(
                                      (elementMapping) =>
                                        elementMapping.elementId
                                    )}
                                  selectEnabled={!isReaderRole && editable}
                                  searchQuery={searchQuery}
                                  onSelect={handleAddProduct}
                                />
                              </Typography>
                            )}
                            {activeTab === 1 && (
                              <Typography component="div" role="tabpanel">
                                <SelectOptionalItemsTable
                                  itemId={itemId}
                                  allOptionalItems={allOptionalItems}
                                  isFetching={isFetchingOptionalItems}
                                  isSuccess={isSuccessOptionalItems}
                                  selectedOptionalItemIds={editableMappedElements
                                    .filter(
                                      (me: DisplayableElementMapping) =>
                                        me.elementType === "Optional Item"
                                    )
                                    .map(
                                      (elementMapping) =>
                                        elementMapping.elementId
                                    )}
                                  selectEnabled={!isReaderRole && editable}
                                  searchQuery={searchQuery}
                                  onSelect={handleAddOptionalItem}
                                />
                              </Typography>
                            )}
                            {activeTab === 2 && (
                              <Typography component="div" role="tabpanel">
                                <SelectMutexTable
                                  itemId={itemId}
                                  allMutexGroups={allMutexGroups}
                                  isFetching={isFetchingMutexGroup}
                                  isSuccess={isSuccessMutexGroup}
                                  selectedMutexIds={editableMappedElements
                                    .filter((me: DisplayableElementMapping) => {
                                      return me.elementType === "Mutex";
                                    })
                                    .map(
                                      (elementMapping) =>
                                        elementMapping.elementId
                                    )}
                                  selectEnabled={!isReaderRole && editable}
                                  searchQuery={searchQuery}
                                  onSelect={handleAddMutex}
                                  onClickNewMutex={handleClickNewMutex}
                                  onClickEditMutex={handleClickEditMutex}
                                />
                              </Typography>
                            )}
                          </div>
                        </Grid>
                        <Grid mobile={6}>
                          <Stack>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <StyledDashboardTableHead>
                                  <TableRow>
                                    <TableCell
                                      style={{ width: "20%" }}
                                      align="center"
                                    >
                                      Name
                                    </TableCell>
                                    <TableCell
                                      style={{ width: "20%" }}
                                      align="right"
                                    >
                                      ID
                                    </TableCell>
                                    <TableCell
                                      style={{ width: "20%" }}
                                      align="center"
                                    >
                                      Qty
                                    </TableCell>
                                    <TableCell
                                      style={{ width: "40%" }}
                                      align="center"
                                    >
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </StyledDashboardTableHead>
                                <TableBody>
                                  {editableMappedElements.map(
                                    (mappedElement, index) => (
                                      <TableRow
                                        key={index}
                                        role="row"
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        data-testid="item-mapped-element-tr"
                                      >
                                        <TableCell
                                          style={{ width: "20%" }}
                                          align="center"
                                        >
                                          {mappedElement.elementName}
                                        </TableCell>
                                        <TableCell
                                          style={{ width: "20%" }}
                                          align="right"
                                        >
                                          {mappedElement.elementId}
                                        </TableCell>
                                        <TableCell
                                          style={{ width: "20%" }}
                                          align="center"
                                        >
                                          <Controller
                                            name={`mappedElements.${index}.qty`}
                                            control={control}
                                            defaultValue={mappedElement.qty}
                                            rules={{
                                              required: "Quantity is required",
                                            }}
                                            render={({ field }) => (
                                              <TextField
                                                disabled={
                                                  !!isReaderRole || !editable
                                                }
                                                {...field}
                                                size="small"
                                                onFocus={() => {
                                                  setValue(
                                                    `mappedElements.${index}.qty`,
                                                    standardizedNumStringOrNA(
                                                      getValues(
                                                        `mappedElements.${index}.qty`
                                                      ),
                                                      selectedCountry!
                                                    )
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setValue(
                                                    `mappedElements.${index}.qty`,
                                                    localizedNumStringOrNA(
                                                      e.target.value,
                                                      selectedCountry!
                                                    )
                                                  );
                                                  field.onBlur();
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell
                                          style={{ width: "40%" }}
                                          align="right"
                                        >
                                          {!isReaderRole && editable && (
                                            <>
                                              {index !== 0 && (
                                                <IconButton
                                                  color="primary"
                                                  onClick={() =>
                                                    handleMoveItem(index, "up")
                                                  }
                                                  disabled={index === 0}
                                                >
                                                  <ArrowUpward />
                                                </IconButton>
                                              )}
                                              {index !==
                                              editableMappedElements.length -
                                                1 ? (
                                                <IconButton
                                                  color="primary"
                                                  onClick={() =>
                                                    handleMoveItem(
                                                      index,
                                                      "down"
                                                    )
                                                  }
                                                  disabled={
                                                    index ===
                                                    editableMappedElements.length -
                                                      1
                                                  }
                                                >
                                                  <ArrowDownward />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  sx={{ visibility: "hidden" }}
                                                  onClick={() =>
                                                    handleMoveItem(
                                                      index,
                                                      "down"
                                                    )
                                                  }
                                                  disabled={
                                                    index ===
                                                    editableMappedElements.length -
                                                      1
                                                  }
                                                >
                                                  <ArrowDownward />
                                                </IconButton>
                                              )}
                                              <IconButton
                                                sx={{ color: "#DA291C", ml: 2 }}
                                                onClick={() =>
                                                  handleRemoveFromMapped(
                                                    mappedElement
                                                  )
                                                }
                                              >
                                                <DeleteOutline />
                                              </IconButton>
                                            </>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {isEmpty && (
                              <StyledEmptyBox>
                                <Typography variant="h6">{`Add Products / Optional Items / Mutex Groups using the arrows`}</Typography>
                              </StyledEmptyBox>
                            )}
                            {customError && (
                              <Alert variant="outlined" severity="error">
                                {customError}
                              </Alert>
                            )}
                            {duplicateError.length > 0 && (
                              <Alert variant="outlined" severity="error">
                                {duplicateError.map((sentence, index) =>
                                  <div key={index}> {sentence.split(', ')}
                                  </div>
                                )}
                              </Alert>
                            )}
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              </Grid>
            )}
            {!isReaderRole && editable && (
              <Grid
                container
                mobile={12}
                sx={{ pr: 1, pt: 3 }}
                justifyContent="flex-end"
              >
                <StyledDangerButton
                  variant="contained"
                  size="large"
                  aria-label="cancel"
                  disabled={
                    !isDirty &&
                    mappedElements.length === editableMappedElements.length
                  }
                  onClick={() => setShowUnsavedChangesModal(true)}
                >
                  Cancel
                </StyledDangerButton>
                <StyledSaveButton
                  sx={{ ml: 4 }}
                  type="submit"
                  variant="contained"
                  size="large"
                  data-testid="save-item-product-mapping"
                >
                  Submit
                </StyledSaveButton>
              </Grid>
            )}
          </Grid>
        </form>
      )}
      {editMutexDialogOpen && (
        <MutexGroupModal
          mutexId={editableMutexId}
          mutexName={editableMutexName}
          onSaveSuccess={onSaveMutexSuccess}
          onSaveError={onSaveMutexError}
          allOptionalItems={allOptionalItems}
          onConfirmClose={handleMutexDialogClose}
          filteredElementIds={filteredElementIds}
        />
      )}
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        title="Confirmation"
        confirmButton="Yes"
        denyButton="No"
        description="Are you sure you want to cancel?"
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={handleErrorAlertClose}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  padding: 0,
  position: "relative",
});
const StyledTopActionPanel = styled(Grid)(({ theme }) => ({
  height: 120,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: 0,
  padding: theme.spacing(2),
}));
const StyledTab = styled(Tab)({
  color: "black",
  fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
  letterSpacing: "-0.15px",
  textTransform: "initial",
  lineHeight: "28px",
  borderBottom: "2px solid #F6F6F6",
  "&.Mui-selected": {
    backgroundColor: "#F6F6F6",
    borderWidth: "4px",
    color: "black",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
});
const StyledSaveButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 380,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.light,
}));
